window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.institution_register = window.locales.am.authentication.institution_register || {};window.locales.am.authentication.institution_register = {...window.locales.am.authentication.institution_register, ...{
    "already_have_an_account": "ቀድሞውኑ መለያ አለዎት? <a ng-click='gotoSignIn(false)'>ይግቡ</a>",           
    "next": "ቀጣይ",           
    "sign_up": "ለ <strong>{{brandName}}</strong> ይመዝገቡ",           
    "free_demo": "{{brandName}} ነፃ ማሳያ",           
    "subtitle_iebusiness": "ለ IE አለም አቀፍ MBA ተማሪዎች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.institution_register = window.locales.ar.authentication.institution_register || {};window.locales.ar.authentication.institution_register = {...window.locales.ar.authentication.institution_register, ...{
    "already_have_an_account": "هل لديك حساب بالفعل؟ <a ng-click='gotoSignIn(false)'>تسجيل الدخول</a>",           
    "next": "التالى",           
    "subtitle_iebusiness": "للطلاب IE الدولية ماجستير في إدارة الأعمال",
    "sign_up": "الاشتراك في <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} عرض توضيحي مجاني"
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.institution_register = window.locales.en.authentication.institution_register || {};window.locales.en.authentication.institution_register = {...window.locales.en.authentication.institution_register, ...{
    "already_have_an_account": "Already have an account? <a ng-click='gotoSignIn(false)'>Login</a>",
    "next": "Next",
    "sign_up": "Sign up for <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} Free Demo",
    "subtitle_iebusiness": "For IE International MBA students"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.institution_register = window.locales.es.authentication.institution_register || {};window.locales.es.authentication.institution_register = {...window.locales.es.authentication.institution_register, ...{
    "already_have_an_account": "¿Ya tienes una cuenta? <a ng-click='gotoSignIn(false)'>Iniciar sesión</a>",           
    "next": "Siguiente",           
    "subtitle_iebusiness": "Para los estudiantes de MBA de IE Internacional",
    "sign_up": "Regístrese para <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} Demo gratis"
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.institution_register = window.locales.it.authentication.institution_register || {};window.locales.it.authentication.institution_register = {...window.locales.it.authentication.institution_register, ...{
    "already_have_an_account": "Hai già un account? <a ng-click='gotoSignIn(false)'>Accedi</a>",           
    "next": "Avanti",           
    "subtitle_iebusiness": "Per studenti MBA IE International",           
    "sign_up": "Iscriviti a <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} Demo gratuita"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.institution_register = window.locales.zh.authentication.institution_register || {};window.locales.zh.authentication.institution_register = {...window.locales.zh.authentication.institution_register, ...{
    "already_have_an_account": "已有帐户？<a ng-click='gotoSignIn(false)'>登录</a>",           
    "next": "下一步",           
    "subtitle_iebusiness": "IE大学国际MBA学员",           
    "sign_up": "注册 <strong>{{brandName}}</strong>",           
    "free_demo": "{{brandName}} 免费演示"           
}
};