import { formattedDateRange } from 'DateHelpers';
import { type AnyObject } from '@Types';
import { type CohortIguanaObject } from 'Cohorts';
import { type StreamIguanaObject } from 'Lessons';

export const createTitleFromPeriod = (
    period: CohortIguanaObject['currentPeriod'],
    formatsText: AnyObject<(text: string) => string>,
) => {
    if (!period) return '';

    const title = formatsText.stripFormatting(period.periodTitle);

    if (!period.canHaveSpecializationStyle || !period.specialization_style) return title;

    return title.replace(/^(week)\s[0-9]{1,2}:\s/i, match => `<span>${match.trim()}</span>`);
};

export const streamsByLocalePackId = (streams: StreamIguanaObject[]) =>
    streams.reduce<AnyObject<StreamIguanaObject>>((prev, curr) => ({ ...prev, [curr.localePackId]: curr }), {});

export const getCoursesFromLocalePackIds = (
    localePackIdHash: AnyObject<StreamIguanaObject>,
    localePackIds: string[],
    findIncomplete = false,
) =>
    [...new Set(localePackIds)].reduce<StreamIguanaObject[]>((courses, localPackId) => {
        const course = localePackIdHash[localPackId];
        if (course && (findIncomplete === false || (findIncomplete === true && course.complete === false))) {
            courses.push(course);
        }
        return courses;
    }, []);

export const getPeriodDates = (relevantCohort: CohortIguanaObject) => {
    const { currentPeriod, periods } = relevantCohort || {};
    if (!currentPeriod) return undefined;

    let { startDate, endDate } = currentPeriod;

    if (currentPeriod.canHaveSpecializationStyle && currentPeriod.specialization_style) {
        const specializationStylePeriods = periods.filter(
            period =>
                period.canHaveSpecializationStyle && period.specialization_style === currentPeriod.specialization_style,
        );
        startDate = specializationStylePeriods.length ? specializationStylePeriods[0].startDate : startDate;
        endDate = specializationStylePeriods.length ? specializationStylePeriods.at(-1)!.endDate : endDate;
    }

    return formattedDateRange(startDate, endDate);
};

export const getPastDueCourses = (
    localePackIdHash: AnyObject<StreamIguanaObject>,
    relevantCohort: CohortIguanaObject,
) => {
    if (relevantCohort.current_period_index === -1) return [];

    const index = relevantCohort.currentPeriod
        ? relevantCohort.current_period_index! - 1
        : relevantCohort.periods.length;

    return getCoursesFromLocalePackIds(
        localePackIdHash,
        relevantCohort.getRequiredStreamPackIdsFromPeriods(index),
        true,
    );
};

export const hideThisWeeksScheduleWatcher = {
    'curriculumStatus,relevantCohort': (scope: AnyObject, val: unknown) => {
        const [curriculumStatus, relevantCohort] = val as [string, AnyObject];

        if (!relevantCohort?.currentPeriod) {
            scope.hide = true;
            return;
        }
        scope.hide = ![
            'week_1',
            'on_track',
            'on_track_finished',
            'not_on_track',
            'almost_there',
            'pre_graduation_not_on_track',
            'pre_graduation_almost_there',
        ].includes(curriculumStatus);
    },
};
