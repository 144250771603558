import {
    type ProgramAchievementGraphicKey,
    type ProgramAchievementGraphicDesignKey,
    type ProgramAchievementGraphicDesign,
} from 'ShareableGraphics/ShareableGraphics.types';
import { type Config as FrontRoyalConfig } from 'FrontRoyalConfig';
import { type InstitutionId } from 'Institutions';
import { type FunctionComponent } from 'react';
import { type ScholarshipCategory } from 'Scholarship';
import { type CurrentUserIguanaObject, type BaseUser } from 'Users';
import { type AnyObject } from '@Types';

export enum Brand {
    quantic = 'quantic',
    valar = 'valar',
    smartly = 'smartly',
    miya_miya = 'miyamiya',
}

export type BrandOrInstitutionId = Brand | InstitutionId;

export type BrandNameProp = 'brandNameAbbr' | 'brandNameShort' | 'brandNameStandard' | 'brandNameLong';

export interface BrandNameConfig {
    brandNameAbbr: string;
    brandNameShort: string;
    brandNameStandard: string;
    brandNameLong: string;
}

export type AdmittedStudentInfo = {
    registrationUrl: string | null;
    recordingUrl: string | null;
    webinarDate: Date | null;
};

export interface BrandConfig extends BrandNameConfig {
    appHeaderHomeTitleNameProperty: BrandNameProp;
    brandStyleClass: Brand;
    acceptedApplicationBadge: string;
    acceptedApplicationLogo: string | null;
    admittedStudentInfo: (config: FrontRoyalConfig) => AdmittedStudentInfo;
    appHeaderStyleClasses: string[];
    appHeaderLearnerLayoutLogo: string | null;
    appHeaderLessonLayoutLogo: string | null;
    appHeaderWordMarkSrc: string;
    appMenuMobileLogo: string;
    appMenuProgramLogo: string | null;
    completeRegistrationLogo: string;
    dashboardFooterLogo: string;
    degreeProgramDurationMonths: number;
    emptyCertDefault: string;
    fundingContactUrl: string | null;
    supportsFeedbackSidebar: boolean;
    supportsMobileAppRateHelper: boolean;
    branding: Brand;
    avatarDefault: string;
    emailAddressForUsername: (username: string) => string;
    defaultNotchBackgroundClass: string;
    institutionId: InstitutionId;
    learnMoreAboutProgramsUrl: string | null;
    learnMoreAboutMbaUrl: string | null;
    learnMoreAboutEmbaUrl: string | null;
    learnMoreAboutMsbaUrl: string | null;
    learnMoreAboutMsseUrl: string | null;
    learnMoreAboutTuitionUrl: string | null;
    lessonFinishScreenShield: string | null;
    loggedOutPageLogo: string;
    onboardingHeaderLogoClass: string | null;
    onboardingHybridStartHex: string | null;
    onboardingHybridStartLogo: string | null;
    ourDegreeProgramsSidebarBoxButtons: string[];
    paperCertificate: string;
    programPreferencesLogo: string | null;
    programPreferencesLogoImgStyles: Record<string, string> | null;
    programPreferencesSubHeadingLocaleKey: string | null;
    programWelcomePackageBadge: string | null;
    scholarshipLabelsMap: Record<ScholarshipCategory, string>;
    sessionsNavigationClosedLogo: string | null;
    sessionsNavigationOpenLogo: string | null;
    shortAnswerWhyPursuingBrandName: string | null;
    stripeImage: string | null;
    studentProfileListCardLogo: string;
    supportsSelectProgram: boolean;
    downloadableCohortScheduleConfig: DownloadableCohortScheduleConfig;
    transcriptImages: TranscriptImages;
    urlPrefix: string;
    themeColor: string;
    programAchievementGraphics: ProgramAchievementGraphicsBrandConfig | null;
    tutorBotFaqUrl: string | null;
    followUsUrl: string | null;
    round62RefundPolicyUrl: string | null;
    avatarAlt: string | null;
    academicAdvisingUrl: string | null;
    fundingEmail: string | null;
}

export type ProgramAchievementGraphicsBrandConfig = {
    [ProgramAchievementGraphicKey.accepted]: {
        component: FunctionComponent<
            AnyObject & {
                selectedDesign: ProgramAchievementGraphicDesign;
                brandConfig: BrandConfig;
                currentUser: CurrentUserIguanaObject;
            }
        >;
        availableDesigns: ProgramAchievementGraphicDesignKey[];
        institutionNameLines: { text: string; fontSize: string }[];
        institutionName?: string;
    };
    [ProgramAchievementGraphicKey.graduated]: {
        institutionName: string | null;
        institutionNameLines?: { text: string; fontSize: string }[];
        component: FunctionComponent<
            AnyObject & {
                selectedDesign: ProgramAchievementGraphicDesign;
                brandConfig: BrandConfig;
                currentUser: CurrentUserIguanaObject;
            }
        >;
        availableDesigns: ProgramAchievementGraphicDesignKey[];
    };
};

interface DownloadableCohortScheduleConfig {
    cover?: string;
    pageA?: string;
    pageB?: string;
    themeColor?: string;
    coverPageTitleX?: number;
    coverPageTitleY?: number;
    coverPageWelcomeMessageY?: number;
}

interface TranscriptImages {
    digitalFrontFull?: string;
    digitalFrontFullUnofficial?: string;
    digitalFrontPartial?: string;
    digitalFrontPartialUnofficial?: string;
    digitalBack?: string;
    digitalBackUnofficial?: string;
    printableFrontFull?: string;
    printableFrontPartial?: string;
    printableBack?: string;
}

export type UserOrDomainOrBrand = BaseUser | string | Brand | undefined | null;

export interface Config {
    isQuantic: () => boolean;
}
