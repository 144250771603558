import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { getBrandName } from 'AppBranding';
import Auid from 'Auid';
import moment from 'moment-timezone';
import { preSignupValues } from 'TinyEventLogger';

angularModule.factory('SignUpFormHelper', [
    '$injector',

    function factory($injector) {
        const AuthFormHelperMixin = $injector.get('AuthFormHelperMixin');
        const JoinConfig = $injector.get('JoinConfig');
        const AClassAbove = $injector.get('AClassAbove');
        const EventLogger = $injector.get('EventLogger');
        const DeviseClient = $injector.get('DeviseClient');
        const Locale = $injector.get('Locale');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('authentication.sign_up_form');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const $location = $injector.get('$location');
        const ClientStorage = $injector.get('ClientStorage');
        const captchaHelper = $injector.get('captchaHelper');
        const ConfigFactory = $injector.get('ConfigFactory');

        return AClassAbove.subclass(() => ({
            initialize(scope) {
                this.scope = scope;
                AuthFormHelperMixin.onLink(
                    scope,
                    this._forwardToNextPage.bind(this),
                    AuthFormHelperMixin.getCustomOmniauthErrorResponder(scope, 'email'),
                );
            },

            getConfig(urlPrefix) {
                const scope = this.scope;
                return JoinConfig.getConfig(urlPrefix).then(urlConfig => {
                    scope.urlConfig = urlConfig;
                    scope.signUpCode = urlConfig.signup_code;
                    EventLogger.log('viewed_join_form', {
                        sign_up_code: urlConfig.signup_code,
                    });
                    return urlConfig;
                });
            },

            onForwardToNextPage(fn) {
                this._clientProvidedForwardToNextPage = fn.bind(this);
            },

            attemptSignIn(registrationInfo) {
                $rootScope.$on('validation-responder:login-success', () => {
                    this._forwardToNextPage();
                });
                return DeviseClient.signIn(registrationInfo).finally(() => {
                    safeApply($rootScope);
                });
            },

            submitRegistration(registrationInfo) {
                const scope = this.scope;
                if (!scope.urlConfig) {
                    throw new Error('urlConfig not set.  Call getConfig()');
                }
                const signUpFormHelper = this;
                scope.preventSubmit = true;
                scope.form_errors = {};

                registrationInfo.sign_up_code = scope.signUpCode;
                if (scope.urlConfig.provider) {
                    registrationInfo.provider = scope.urlConfig.provider;
                }

                // If the user has logged events with an anonymous user id,
                // use that id when creating the user
                const auid = Auid.get($rootScope.currentUser);
                if (auid) {
                    registrationInfo.id = auid;
                }

                // pull skip_apply from the query params (if available) and pass up to the server
                const skip_apply = $location.search() && $location.search().skip_apply;
                if (skip_apply) {
                    registrationInfo.skip_apply = skip_apply;
                }

                // pull the user id stored from a referral link (if available) and pass up to the server
                const referredById = ClientStorage.getItem('referredById');
                if (referredById) {
                    registrationInfo.referred_by_id = referredById;
                }

                // set the user's locale
                registrationInfo.pref_locale = Locale.defaultLocaleForSignUpCode(scope.signUpCode);

                // set the user's timezone
                registrationInfo.timezone = moment.tz.guess();

                function showErrors(errors) {
                    scope.preventSubmit = false;
                    if (errors) {
                        // FIXME: We should consolidate these into a single locale entry per error per unique error field / type
                        // combination, as this does not actually translate correctly (assumes leading subject).
                        // This may spiral all the way to how devise_token_auth does localizations

                        if (errors.name) {
                            scope.form_errors.name = `${translationHelper.get('name')} ${errors.name}`;
                        }
                        if (errors.email) {
                            scope.form_errors.email = errors.full_messages[0];
                        }
                        if (errors.password) {
                            scope.form_errors.password = `${translationHelper.get('password')} ${errors.password}`;
                        }
                        if (errors.professional_organization) {
                            scope.form_errors.professional_organization = `${translationHelper.get('company')} ${
                                errors.professional_organization
                            }`;
                        }
                        if (errors.job_title) {
                            scope.form_errors.job_title = `${translationHelper.get('job_title')} ${errors.job_title}`;
                        }
                        if (errors.phone) {
                            // FIXME: (see above ^) this is SOOOOOO gross. see also (en.yml, es.yml, zh.yml)
                            const phoneStr = errors.phone[0] || '';
                            if (
                                phoneStr.includes('phone number already exists') || // english
                                phoneStr.includes('teléfono ya existe') || // spanish
                                phoneStr.includes('con quel numero di telefono') || // italian
                                phoneStr.includes('与该电话号码的帐户已经存在') || // chinese
                                phoneStr.includes('حساب مع رقم الهاتف موجود بالفعل') || // arabic
                                phoneStr.includes('የስልክ ቁጥር አስቀድሞ አለ') // amharic
                            ) {
                                scope.form_errors.phone = phoneStr;
                            } else {
                                scope.form_errors.phone = `${translationHelper.get('phone')} ${phoneStr}`;
                            }
                        }
                        if (errors.country) {
                            scope.form_errors.country = `${translationHelper.get('country')} ${errors.country}`;
                        }
                    } else {
                        scope.form_errors.general = translationHelper.get('server_error', {
                            brandName: getBrandName(undefined, ConfigFactory.getSync(), 'short'),
                        });
                    }
                }

                // add things like which candidates they have seen and liked on marketing pages
                _.extend(registrationInfo, preSignupValues.allServer());

                EventLogger.log('auth:submit_registration', {
                    provider: registrationInfo.provider,
                    log_to_customerio: true,
                });

                DeviseClient.register(registrationInfo)
                    .then(
                        response => {
                            // Ng-token auth isn't broadcasting this event for some reason upon successful registration anymore...
                            $rootScope.$broadcast('auth:validation-success', response.data.data);

                            const onboardingQuestions = ClientStorage.getItem('onboardingQuestions');
                            if (onboardingQuestions) {
                                EventLogger.log('auth:registration-success', {
                                    onboarding_questions: JSON.parse(onboardingQuestions),
                                    log_to_customerio: true,
                                });
                            }

                            const listener = $rootScope.$on('validation-responder:login-success', () => {
                                listener();
                                signUpFormHelper._forwardToNextPage();
                            });
                            scope.$on('$destroy', listener);

                            // note: don't set preventsubmit to false, we want to keep the form locked while we transition
                        },
                        response => {
                            // If registration failed for whatever reason, reset the captcha because
                            // the tokens have been consumed.
                            captchaHelper.resetCaptcha();

                            let errors;
                            let emailExistsError;
                            try {
                                errors = response && response.data && response.data.errors;

                                // FIXME: I'm seeing a message that looks like the second text here, but the code is
                                // expecting the first one.  Not sure if it has changed, or if it's variable or what.
                                // Probably will end up being localized if someone's browser is in another supported
                                // language.
                                const emailError = errors.email.toString(); // in case it is an array
                                emailExistsError =
                                    emailError.includes('already in use') || emailError.includes('already been taken');
                            } catch (e) {
                                // no-op
                            }

                            if (emailExistsError) {
                                return this.attemptSignIn(registrationInfo).catch(() => {
                                    showErrors(errors);
                                });
                            }
                            showErrors(errors);

                            return undefined;
                        },
                    )
                    .finally(() => {
                        safeApply($rootScope);
                    });
            },

            _forwardToNextPage() {
                if (this._clientProvidedForwardToNextPage) {
                    this._clientProvidedForwardToNextPage();
                }
            },
        }));
    },
]);
