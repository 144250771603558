import { asStatsig, type CurrentUserIguanaObject } from 'Users';
import { statsigClientProvider } from 'StatsigClientProvider';
import sha256 from 'sha256';
import { SegmentioHelper } from 'Analytics';

export default async function identifyUser(user: CurrentUserIguanaObject) {
    if (!user) return;

    // Identify in Segment
    window.analytics?.identify(user.id, SegmentioHelper.getIdentifyPayload(user));

    // Push attributes to the GTM data layer
    const sha256Values = await Promise.all([sha256(user.email), sha256(user.phone)]);
    window.dataLayer?.push({
        sha256_email_address: sha256Values[0],
        sha256_phone_number: sha256Values[1],
    });

    // Update Statsig user
    statsigClientProvider
        .waitForClient({ caller: 'identifyUser' })
        .then(client => client.updateUserSync(asStatsig(user)));
}
