import { type AdmittedStudentInfo } from 'AppBranding';
import { type TranslationHelperInstance } from 'Translation';
import { formattedUserFacingMonthDayLong } from 'DateHelpers';
import { type LinkTarget } from 'NavigationHelpers';
import { type SidebarBoxConfig, SidebarBoxKey, type AdmittedStudentsZoomStatus } from './StudentDashboard.types';
import admittedStudentsZoomStatus from './admittedStudentsZoomStatus';

type Props = {
    admittedStudentInfo: AdmittedStudentInfo;
    translationHelper: TranslationHelperInstance;
    loadUrl: (url: string | null | undefined, target: LinkTarget) => void;
};

type StatusConfig = {
    headingLocaleKey: string | null;
    buttonLocaleKey: string | null;
    textLocaleKey: string | null;
    ctaUrlKey: Extract<keyof AdmittedStudentInfo, 'registrationUrl' | 'recordingUrl'> | null;
    translationParams?: (admittedStudentInfo: AdmittedStudentInfo) => Record<string, string> | undefined;
};

const statusConfigs: Record<AdmittedStudentsZoomStatus, StatusConfig> = {
    saveTheDate: {
        headingLocaleKey: 'heading_admitted_students_zoom_save_the_date',
        buttonLocaleKey: 'button_view_and_schedule',
        textLocaleKey: 'content_admitted_students_zoom_you_are_invited',
        ctaUrlKey: 'registrationUrl',
        translationParams: admittedStudentInfo => ({
            webinarMonthDay: formattedUserFacingMonthDayLong(admittedStudentInfo.webinarDate, false),
        }),
    },
    watchRecording: {
        headingLocaleKey: 'heading_admitted_students_zoom_q_and_a',
        buttonLocaleKey: 'watch_recording',
        textLocaleKey: 'content_admitted_students_if_you_missed_the_zoom',
        ctaUrlKey: 'recordingUrl',
    },
    hidden: {
        headingLocaleKey: null,
        buttonLocaleKey: null,
        textLocaleKey: null,
        ctaUrlKey: null,
    },
};

export default function admittedStudentsZoomConfig({
    admittedStudentInfo,
    translationHelper,
    loadUrl,
}: Props): SidebarBoxConfig {
    const status = admittedStudentsZoomStatus(admittedStudentInfo);
    const statusConfig = statusConfigs[status];

    const headingLocaleKey = statusConfig.headingLocaleKey;
    const buttonLocaleKey = statusConfig.buttonLocaleKey;
    const textLocaleKey = statusConfig.textLocaleKey;
    const ctaUrlKey = statusConfig.ctaUrlKey;
    const translationParams = statusConfig.translationParams?.(admittedStudentInfo);
    const text = textLocaleKey ? translationHelper.get(textLocaleKey, translationParams) : '';
    const url = ctaUrlKey ? admittedStudentInfo[ctaUrlKey] : null;

    return {
        key: SidebarBoxKey.admitted_students_zoom,
        heading: headingLocaleKey ? translationHelper.get(headingLocaleKey) : '',
        mobileContentHtml: text,
        desktopContentHtml: text,
        buttons: buttonLocaleKey
            ? [
                  {
                      id: 'admitted-students-zoom-cta',
                      text: translationHelper.get(buttonLocaleKey),
                      onClick: () => {
                          loadUrl(url!, '_blank');
                      },
                  },
              ]
            : undefined,
        shouldHide: status === 'hidden',
    };
}
