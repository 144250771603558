import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { QUANTIC_DOMAIN, VALAR_DOMAIN } from 'PedagoDomainConstants';
import smartlyLogoRed from 'vectors/logo-red.svg';
import quanticLogoRed from 'vectors/logo-red_quantic.svg';

export const DEFAULT_URL_PREFIX = 'default';

angularModule.factory('JoinConfig', [
    '$injector',
    $injector => {
        const ConfigFactory = $injector.get('ConfigFactory');
        const $window = $injector.get('$window');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $rootScope = $injector.get('$rootScope');
        const SuperModel = $injector.get('SuperModel');

        const JoinConfig = SuperModel.subclass(function () {
            this.extend({
                // return the url config data structure based on url prefix, falling back to specified default
                getConfig(urlPrefix) {
                    return this._getJoinConfig().then(joinConfig => {
                        if ($window.CORDOVA) {
                            if ($window.CORDOVA.forcedUrlPrefix) {
                                urlPrefix = $window.CORDOVA.forcedUrlPrefix;
                            }
                        }

                        // We could have a current user when we are on a sign-in page
                        // if the user was logged out in the middle of a session.  In that
                        // case, check the user's sign up code to see if we should use
                        // a particular configuration.
                        if (!urlPrefix && joinConfig.configForUser($rootScope.currentUser)) {
                            return joinConfig.configForUser($rootScope.currentUser);
                        }

                        if (!urlPrefix) {
                            urlPrefix = DEFAULT_URL_PREFIX;
                        }

                        return joinConfig.configForUrlPrefix(urlPrefix);
                    });
                },

                _getJoinConfig() {
                    // Load up the join_config from the server,
                    // and then merge in values that only the client
                    // needs.
                    if (!this._joinConfigPromise) {
                        this._joinConfigPromise = ConfigFactory.getConfig().then(
                            config => new JoinConfig(config.join_config),
                        );
                    }
                    return this._joinConfigPromise;
                },
            });

            return {
                initialize(configs) {
                    // clone it
                    configs = JSON.parse(JSON.stringify(configs));

                    // default configuration
                    const _default = {
                        title_message: 'authentication.institution_register.sign_up',
                        sign_in_title_message: 'authentication.sign_in.login',
                        showSignUpSideBars: false,
                        included_field_ids: ['name', 'email', 'password'],
                        provider: 'email',
                        smartly_logo_img: smartlyLogoRed,
                        quantic_logo_img: quanticLogoRed,
                        // sign_up_message: 'authentication.sign_up_form.sign_up_for_free_using',
                        submit_button_text: 'authentication.sign_up_form.join',
                        domain: QUANTIC_DOMAIN,
                    };

                    // handle standard defaults
                    _.forEach(configs, entry => {
                        _.extend(entry, _default);
                    });

                    // handle any special-case client-only configuration schemes
                    const clientSideOverrides = {
                        gwstatistics: {
                            disable_oauth: true,
                        },
                        jmu: {
                            disable_oauth: true,
                        },
                        emiratesdb: {
                            disable_oauth: true,
                        },
                        georgetownmsb: {
                            sign_up_message: 'authentication.sign_up_form.sign_up_using_georgetown_email',
                            disable_oauth: true,
                            email_placeholder: 'you@georgetown.edu',
                        },
                        nyu: {
                            sign_up_message: 'authentication.sign_up_form.sign_up_using_nyu_email',
                            disable_oauth: true,
                            email_placeholder: 'you@stern.nyu.edu',
                        },
                        iebusiness: {
                            disable_oauth: true,
                            email_placeholder: 'ie.edu email',
                            show_title_message_on_mobile: true,
                            subtitle_message: 'authentication.institution_register.subtitle_iebusiness',
                        },
                        advancedplacement: {
                            disable_oauth: true,
                        },
                        inframark: {
                            sign_up_message: 'authentication.sign_up_form.sign_up_using_inframark_email',
                            disable_oauth: true,
                            email_placeholder: 'you@inframark.com',
                        },
                        miyamiya: {
                            title_message: 'authentication.sign_up_form.miyamiya_title_message',
                            sign_in_title_message: 'authentication.sign_in.login_to_miyamiya',
                            disable_oauth: true,
                        },
                        tjhs: {
                            disable_oauth: true,
                        },
                        valar: {
                            title_message: 'authentication.sign_up_form.valar_title_message',
                            sign_in_title_message: 'authentication.sign_in.login_to_valar',
                            submit_button_text: 'authentication.sign_up_form.sign_up',
                            show_privacy_notice: true,
                            show_title_message_on_mobile: true,
                            show_context_sidebar: true,
                            alternate_form_locales: true,
                            full_width_navigation: true,
                            domain: VALAR_DOMAIN,
                            sidebar_bullet_locale_keys: [
                                'apply_mba_degree_programs',
                                'immediate_access',
                                'preview_global_alumni_network',
                            ],
                        },
                        corporatetraining: {
                            disable_oauth: true,
                            require_company_email: true,
                            included_field_ids: ['email', 'name', 'job_title', 'company', 'phone', 'password'],
                            field_overrides: {
                                phone: {
                                    defaultCountryCode: 'US',
                                    optional: true,
                                },
                            },
                        },
                    };

                    // apply overrides if found in configs
                    _.forEach(clientSideOverrides, (value, key) => {
                        if (configs[key]) {
                            // do not use a deep merge, or odd things will happen
                            // to fields like the `included_field_ids` array
                            angular.extend(configs[key], value);
                        }
                    });

                    this.default = configs.default;
                    this._configs = configs;
                },

                configForUrlPrefix(urlPrefix) {
                    urlPrefix = urlPrefix.replace(/\/$/g, ''); // remove trailing slash
                    const config = this._configs[urlPrefix];
                    if (!config) {
                        ErrorLogService.notify(`No urlConfig for '${urlPrefix}'`);
                        urlPrefix = DEFAULT_URL_PREFIX;
                    }

                    return this._configs[urlPrefix];
                },

                configForUser(user) {
                    if (!user) {
                        return undefined;
                    }

                    return _.find(this._configs, {
                        signup_code: user.sign_up_code,
                    });
                },
            };
        });

        return JoinConfig;
    },
]);
