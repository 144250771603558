import { type CohortClass } from 'Cohorts';
import { type DialogModal } from 'DialogModal';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { keyBy } from 'lodash/fp';
import { navigationHelper } from 'NavigationHelpers';
import { getFilteredExecEdEligibilityBundle, getRelevantCohorts } from 'Users';

export const showExecEdEligibilityBundle = ($injector: angular.auto.IInjectorService) => {
    const DialogModal = $injector.get<DialogModal>('DialogModal');
    const Cohort = $injector.get<CohortClass>('Cohort');
    const { loadUrl } = navigationHelper();
    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser!;

    if (!currentUser.execEdEligibilityBundle) return;

    const filteredEligibilityBundle = getFilteredExecEdEligibilityBundle(
        currentUser.execEdEligibilityBundle,
        currentUser.admissionOffers,
        getRelevantCohorts(currentUser),
    );

    DialogModal.alert({
        content: `
            <cert-congrats-modal
                current-user="currentUser"
                filtered-eligibility-bundle="filteredEligibilityBundle"
                program-type-configs="programTypeConfigs"
                load-url="loadUrl"
            ></cert-congrats-modal>`,
        size: 'fullscreen',
        hideCloseButton: false,
        closeOnClick: false,
        scope: {
            currentUser,
            filteredEligibilityBundle,
            programTypeConfigs: keyBy('key')(Cohort.programTypes),
            loadUrl,
        },
        classes: ['exec-ed-cert-congrats-modal'],
    });

    // Ephemeral flag to prevent the modal from showing again on this page load unless triggered by CTA click
    currentUser.hasSeenCertCongratsModalThisPageLoad = true;
};
