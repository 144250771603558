import { type Nullable } from '@Types';
import moment from 'moment-timezone';
import { type Cohort, COHORT_SECTIONS, getRegistrationDeadline as getRegistrationDeadlineForCohort } from 'Cohorts';
import { type AdmissionOffer, AdmissionOfferStatus } from './AdmissionOffer.types';

export const offeredAdmission = (admissionOffer: Nullable<AdmissionOffer>) =>
    admissionOffer?.status === AdmissionOfferStatus.OfferedAdmission;

export const acceptedAdmissionOffer = (admissionOffer: Nullable<AdmissionOffer>) =>
    admissionOffer?.status === AdmissionOfferStatus.AcceptedAdmissionOffer;

export function declinedAdmissionOffer(admissionOffer: Nullable<AdmissionOffer>) {
    return admissionOffer?.status === AdmissionOfferStatus.DeclinedAdmissionOffer;
}

export function earlyRegistrationDeadlineMs(admissionOffer: Nullable<AdmissionOffer>): Nullable<number> {
    return earlyRegistrationDeadlineMoment(admissionOffer)?.valueOf() || null;
}

function earlyRegistrationDeadlineMoment(admissionOffer: Nullable<AdmissionOffer>): Nullable<moment.Moment> {
    if (!admissionOffer || !admissionOffer.earlyRegistrationDeadline) return null;

    return moment(1000 * admissionOffer.earlyRegistrationDeadline).startOf('day');
}

export function inEarlyAdmissionsRegistrationPeriod(admissionOffer: AdmissionOffer) {
    if (!admissionOffer) return false;

    const earlyAdmissionRegistrationDeadline = earlyRegistrationDeadlineMs(admissionOffer);
    return !!earlyAdmissionRegistrationDeadline && Date.now() < earlyAdmissionRegistrationDeadline;
}

// TODO: Remove after the outreach for the Apex 68 scholarship is over
export function isAiOutreach68(admissionOffer: AdmissionOffer) {
    return admissionOffer?.reportsTags?.includes('ai_outreach_68');
}

export function getRegistrationDeadline(admissionOffer: AdmissionOffer, cohort: Cohort): Date | null {
    if (!admissionOffer) return null;

    if (inEarlyAdmissionsRegistrationPeriod(admissionOffer)) {
        const asMoment = earlyRegistrationDeadlineMoment(admissionOffer);
        if (!asMoment) {
            throw new Error(
                'How could earlyRegistrationDeadlineMoment return null when inEarlyAdmissionsRegistrationPeriod is true?',
            );
        }
        return asMoment.toDate();
    }

    return isAiOutreach68(admissionOffer)
        ? moment(getRegistrationDeadlineForCohort(cohort)).add(8, 'days').toDate()
        : getRegistrationDeadlineForCohort(cohort);
}

export function getGlobalCohortSectionOffer(admissionOffer: AdmissionOffer) {
    return admissionOffer.cohortSectionOffers.find(cso => cso.cohortSectionId === COHORT_SECTIONS.GLOBAL.id);
}
