import { targetBrandConfig } from 'AppBranding';
import moment from 'moment-timezone';
import * as userAgentHelper from 'userAgentHelper';
import angularModule from './authentication_module';

angularModule.factory('AuthFormHelperMixin', [
    '$injector',

    function factory($injector) {
        const $q = $injector.get('$q');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const $http = $injector.get('$http');
        const DeviseClient = $injector.get('DeviseClient');
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const $window = $injector.get('$window');
        const omniauth = $injector.get('omniauth');
        const ConfigFactory = $injector.get('ConfigFactory');
        const blockAuthenticatedAccess = $injector.get('blockAuthenticatedAccess');
        const $location = $injector.get('$location');
        const DialogModal = $injector.get('DialogModal');
        const TranslationHelper = $injector.get('TranslationHelper');
        const ErrorLogService = $injector.get('ErrorLogService');
        const ClientStorage = $injector.get('ClientStorage');
        const EventLogger = $injector.get('EventLogger');
        const HttpQueue = $injector.get('HttpQueue');

        const isCustomCodeError = errorMessage =>
            ['error_account_deactivated', 'error_account_in_deletion_queue'].includes(errorMessage);

        function safeStringify(value) {
            try {
                return JSON.stringify(value);
            } catch (e) {
                return `Unserializable value: ${typeof value}`;
            }
        }

        const isIgnorableError = error => {
            const message = (error.errors && error.errors[0]) || error?.data?.message || safeStringify(error);
            return (
                isCustomCodeError(message) ||
                [
                    'cancel',
                    '1001', // iOS SIWA cancel code
                    '用户点击取消并返回', // WeChat cancel, translates to "User clicks cancel and returns"
                ].includes(message)
            );
        };

        const translationHelper = new TranslationHelper('authentication.auth_form_helper_mixin');

        return {
            onLink(scope, onOmniauthSuccess, onOmniauthFailure) {
                scope.preventSubmit = false;

                scope.form_errors = {};

                if (blockAuthenticatedAccess.block) {
                    scope.form_errors.general = blockAuthenticatedAccess.errorMessage;
                    scope.preventSubmit = true;
                }

                // registers the user with ngDeviseTokenAuthClient and signal login success
                function handleAuthPassthrough(user) {
                    // https://trello.com/c/sbNR92fp - No longer necessary after Devise switchover
                    ngDeviseTokenAuthClient.initDfd();
                    return ngDeviseTokenAuthClient
                        .handleValidAuth(user, true)
                        .then(() => {
                            $rootScope.$broadcast('auth:login-success', user);
                        })
                        .finally(() => {
                            safeApply($rootScope);
                        });
                }

                function showDialogModalWithErrorMessage(errorMessage) {
                    if (!angular.isDefined(errorMessage)) {
                        return;
                    }

                    DialogModal.alert({
                        content: errorMessage, // should already be localized
                        classes: ['server-error-modal'],
                        title: translationHelper.get('server_error'),
                    });
                }

                // If there are errors during omniauth login, then
                // the will be included as a query param.  I would like
                // to remove the query param, but for some reason
                // I saw this directive rendered twice consecutively.  If
                // the first one deletes the query param, then there will
                // be no message. (We need to uniq because repeated failures dupe the message)
                if ($location.search().error) {
                    const error = $location.search().error;

                    if (isCustomCodeError(error)) {
                        showDialogModalWithErrorMessage(
                            translationHelper.get(error, {
                                brandEmail: targetBrandConfig(
                                    undefined,
                                    ConfigFactory.getSync(),
                                ).emailAddressForUsername('support'),
                            }),
                        );
                    } else {
                        scope.form_errors = scope.form_errors || {};
                        scope.form_errors.general = Array.isArray(error) ? _.uniq(error) : error;
                    }
                }

                function getUserFromProviderParams(provider, params) {
                    const receivedMalformedButSuccessfulErrorMessage =
                        'Received malformed but successful callback response (native)';

                    // make endpoint call to update user and return auth_token values
                    return $http({
                        url: `${window.ENDPOINT_ROOT}/native_oauth/${provider}/callback.json`,
                        method: 'GET',
                        params,
                    }).then(response => {
                        // ensure we don't have a malformed / invalid 200 response
                        if (response && response.data && response.data.data) {
                            return response.data.data;
                        }
                        throw new Error(receivedMalformedButSuccessfulErrorMessage);
                    });
                }

                // scoped for testing
                scope.getNonceValue = () => Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 1)) + 1;

                // needed on scope for tests
                scope.getProviderPromise = (provider, signUpCode, programType) => {
                    // programType is only passed through to omniauth login, not to native
                    // login.  This is ok, since it is only set on marketing pages and so
                    // will never be set in the native situation.
                    if (!$window.CORDOVA) return omniauth.loginWithProvider(provider, signUpCode, programType);

                    const failedToRetrieveAuthTokenErrorMessage = 'Failed to retrieve auth token from (native)';
                    const failedToValidateClientNonce = 'Failed to validate client nonce for FB Limited Login (native)';
                    const loginFields = ['public_profile', 'email'];
                    const baseParams = {
                        sign_up_code: signUpCode,
                        timezone: moment.tz.guess(),
                    };

                    // WeChat Native
                    if (provider === 'wechat_native') {
                        return $q((resolve, reject) => {
                            const authScope = 'snsapi_userinfo';
                            const state = `_${new Date()}`;

                            window.Wechat.auth(
                                authScope,
                                state,
                                response => {
                                    resolve({ ...baseParams, code: response.code });
                                },
                                reason => {
                                    if (reason === '发送请求失败') {
                                        // customize the error message when WeChat is not installed on Android
                                        try {
                                            window.Wechat.isInstalled(installed => {
                                                if (!installed) {
                                                    reason = translationHelper.get('please_install_wechat');
                                                }
                                                reject(reason);
                                            }, reject);
                                        } catch {
                                            reject(reason);
                                        }
                                    } else {
                                        reject(reason);
                                    }
                                },
                            );
                        });
                    }

                    // Facebook Native
                    if (provider === 'facebook' && window.facebookConnectPlugin) {
                        return $q((resolve, reject) => {
                            // validate login and kick off access token callbacks
                            if (userAgentHelper.isiOSoriPadOSDevice()) {
                                const nonceVal = `${scope.getNonceValue()}`;

                                window.facebookConnectPlugin.loginWithLimitedTracking(
                                    loginFields,
                                    nonceVal,
                                    loginResponse => {
                                        if (nonceVal !== loginResponse?.authResponse?.nonce) {
                                            ErrorLogService.notify(failedToValidateClientNonce, undefined, {
                                                provider,
                                                expectedNonce: nonceVal,
                                                providedNonce: loginResponse?.authResponse?.nonce,
                                            });
                                            reject('Validation failed');
                                        }

                                        resolve({
                                            ...baseParams,
                                            authentication_token: loginResponse.authResponse?.authenticationToken,
                                            nonce: nonceVal,
                                        });
                                    },
                                    reject,
                                );
                            } else {
                                window.facebookConnectPlugin.login(
                                    loginFields,
                                    () => {
                                        // now get the token
                                        window.facebookConnectPlugin.getAccessToken(
                                            accessToken => {
                                                resolve({ ...baseParams, access_token: accessToken });
                                            },
                                            tokenError => {
                                                ErrorLogService.notify(
                                                    failedToRetrieveAuthTokenErrorMessage,
                                                    undefined,
                                                    {
                                                        tokenError: safeStringify(tokenError),
                                                        provider,
                                                    },
                                                );
                                                reject(tokenError);
                                            },
                                        );
                                    },
                                    reject,
                                );
                            }
                        });
                    }

                    // Google Native
                    if (provider === 'google_oauth2' && window.plugins && window.plugins.googleplus) {
                        return $q((resolve, reject) => {
                            // get the clientId required to get idToken values in the response
                            const webClientId = ConfigFactory.getSync().google_oauth_id;

                            // Google is a little annoying in that the webClient is needed, as well as the plugin configuration pointing to a
                            // SHA-1 mapped token generated from production certificates. ie - the webClientId needs to match the same "production"
                            // environment. Be sure you account for this in application.env if testing native Google Sign-In
                            window.plugins.googleplus.login(
                                {
                                    // NOTE: this is only used by Android and will be reflected as the aud in identity token
                                    webClientId,
                                },
                                providerResponse => {
                                    resolve({ ...baseParams, provider_data: providerResponse });
                                },
                                reject,
                            );
                        });
                    }

                    // Apple Native
                    // Note that Android does not have native support for Sign In With Apple, so we do not expose Apple as an OAuth provider
                    // on Native Android.
                    if (
                        ['apple_quantic', 'apple_smartly'].includes(provider) &&
                        window.cordova?.plugins?.SignInWithApple
                    ) {
                        return $q((resolve, reject) => {
                            window.cordova.plugins.SignInWithApple.signin(
                                { requestedScopes: [0, 1] },
                                providerResponse => {
                                    resolve({ ...baseParams, provider_data: providerResponse });
                                },
                                reject,
                            );
                        });
                    }

                    throw new Error('Unsupported native provider');
                };

                // This function is used when there is one button on the screen (i.e. "Sign in with Apple" or "Sign in with Wechat")
                // and then we need to dynamically decide which provider to use (i.e. "apple_quantic" or "wechat_official_account")
                function getProvider(providerFamily) {
                    if (providerFamily === 'apple') {
                        const config = ConfigFactory.getSync();
                        return config.isQuantic() ? 'apple_quantic' : 'apple_smartly';
                    }

                    if (providerFamily === 'wechat') {
                        if (window.Wechat) {
                            return 'wechat_native';
                        }

                        return userAgentHelper.isWeChatBrowser() ? 'wechat_official_account' : 'wechat_web';
                    }

                    return providerFamily;
                }

                scope.loginWithProvider = (providerFamily, signUpCode, programType) => {
                    const provider = getProvider(providerFamily);

                    // We know that it is possible for the signUpCode to not be set yet in
                    // CORDOVA, but that shouldn't be a problem as we default the signUpCode on
                    // the server-side if it doesn't exist. See - https://trello.com/c/JDHMbJQr
                    if (!signUpCode && !$window.CORDOVA) {
                        ErrorLogService.notify('loginWithProvider called with no signUpCode');
                    }

                    scope.preventSubmit = true;

                    // Since logging in with oauth will send us away from the page,
                    // try to make extra sure these events get logged.
                    EventLogger.log('auth:login_with_provider', { provider });

                    // OAuth providers have mixed whitelisting allowances, and in some instances will
                    // not allow us to support dynamic query strings. As such, we'll translate redirect
                    // target requests into a form that will persist throughout a desktop browser OAuth
                    // authentication flow (redirects to provider / redirects back)
                    if (!$window.CORDOVA) {
                        const redirectTarget = $location.search().target;
                        if (redirectTarget) {
                            ClientStorage.setClientDomainCookie('oauth_redirect_target', redirectTarget);
                        }
                    }

                    // Note that we need to wait for the request to finish before starting the OAuth
                    // flow because of concurrency issues with the session state (which is actually
                    // only needed for OAuth). Theoretically it's not possible right now to completely
                    // eliminate concurrency issues here because auth requests are exempt from the HttpQueue,
                    // and the status ping could theoretically happen at just the right time, and tinyEventLogger
                    // requests are outside Angular and thus not using the HttpQueue. But practically this
                    // particular events request is the main offender since it's happening at the same time we
                    // initiate OAuth.
                    // See https://trello.com/c/905quJK9
                    // BUT, if the queue is blocked by a failed request (and so we're showing the login modal), then
                    // we can't flush the events (and we may want to, since we may have failed to save something else)
                    // see https://trello.com/c/6SKC69NI
                    const promise = HttpQueue.blockedByFailedRequest ? $q.resolve() : EventLogger.tryToSaveBuffer();
                    promise
                        .then(() => scope.getProviderPromise(provider, signUpCode, programType))
                        // We will not get into these callbacks on web since omniauth.loginWithProvider
                        // will forward us to a different page. We only get here on cordova using a
                        // provider plugin.
                        .then(params => getUserFromProviderParams(provider, params))
                        .then(user => handleAuthPassthrough(user))
                        .then(() => {
                            scope.preventSubmit = false;
                            scope.form_errors = {};

                            // Wait for ValidationResponder to tell us we've authenticated, to ensure
                            // we have a currentUser before running the success callback
                            const stopListeningForLoginSuccess = $rootScope.$on(
                                'validation-responder:login-success',
                                () => {
                                    if (onOmniauthSuccess) {
                                        onOmniauthSuccess.call(this);
                                    }
                                    stopListeningForLoginSuccess();
                                },
                            );
                            scope.$on('$destroy', () => {
                                stopListeningForLoginSuccess();
                            });
                        })
                        .catch(error => {
                            scope.preventSubmit = false;

                            // clear any temporary redirect targets
                            ClientStorage.removeItem('oauth_redirect_target');

                            safeApply($rootScope);

                            if (!isIgnorableError(error)) {
                                ErrorLogService.notify('Failed to login (native)', undefined, {
                                    parsedError: safeStringify(error),
                                    provider,
                                });
                            }

                            if (onOmniauthFailure) {
                                onOmniauthFailure.call(this, error);
                            }
                        });
                };

                scope.formErrorClasses = fieldName => {
                    if (scope.form_errors[fieldName]) {
                        return ['form-error', 'active'];
                    }
                    return ['form-error'];
                };

                scope.formValidationClasses = fieldName => {
                    if (scope.form_errors[fieldName]) {
                        return ['form-control', 'ng-invalid'];
                    }
                    return ['form-control'];
                };

                scope.uniqueErrors = errors => {
                    if (_.isArray(errors)) {
                        errors = _.uniq(errors).join('\n');
                    }
                    return errors;
                };

                scope.submitLogin = signInInfo => {
                    scope.preventSubmit = true;
                    scope.form_errors = {};

                    if (!signInInfo.provider) {
                        signInInfo.provider = scope.urlConfig.provider;
                    }

                    if (window.CORDOVA?.miyaMiya) {
                        signInInfo.client = 'miya_miya';
                    }

                    return (
                        DeviseClient.signIn(signInInfo)
                            // using then/catch here rather than .finally to prevent
                            // rejected promises from bubbling up to the caller
                            .then(() => {
                                scope.preventSubmit = false;
                                safeApply($rootScope);
                            })
                            .catch(() => {
                                scope.preventSubmit = false;
                                safeApply($rootScope);
                            })
                    );
                };

                // see https://trello.com/c/0YERueRg/102-disable-wechat-sign-on-on-the-mobile-web-browsers
                const isMobileOrTabletBrowser =
                    !$window.CORDOVA && userAgentHelper.isMobileOrTabletDevice() && !userAgentHelper.isWeChatBrowser();
                scope.showWechatProvider = !isMobileOrTabletBrowser;

                // ngDeviseTokenAuthClient sanitizes all sign-in errors as "Invalid Credentials"
                // but we have a specific use-case where we want to suggest OAuth
                // providers. It's leaky, but provides a nice UX.
                const errorListener = $rootScope.$on('auth:login-error', (_event, eventResponse) => {
                    // these should all be localized at this point
                    if (eventResponse && eventResponse.errors && eventResponse.errors[0]) {
                        // Don't set the general error message if it's a custom response
                        if (!isCustomCodeError(eventResponse.errors[0])) {
                            scope.form_errors.general = eventResponse.errors[0];
                        }
                    } else if (eventResponse) {
                        // On a failed invalid user login attempt the server will return a message string for
                        // eventResponse rather than an object. This distinction allows us to differentiate between
                        // the different server responses.

                        if (isCustomCodeError(eventResponse)) {
                            eventResponse = translationHelper.get(eventResponse, {
                                brandEmail: targetBrandConfig(
                                    undefined,
                                    ConfigFactory.getSync(),
                                ).emailAddressForUsername('support'),
                            });
                        }

                        // The listener on 'auth:login-error' events overrides front-royal-api-error-handler,
                        // so we need to explicity create the DialogModal alert.
                        showDialogModalWithErrorMessage(eventResponse);
                    }
                });

                scope.$on('$destroy', () => {
                    if (errorListener) {
                        errorListener();
                    }
                });
            },

            // Provides an error responder capable of dealing with our special-case error codes
            getCustomOmniauthErrorResponder(scope, fieldName) {
                fieldName = fieldName || 'general';

                const getCustomErrorMessage = errorResponse => {
                    if (!errorResponse) {
                        return null;
                    }
                    if (errorResponse.data && errorResponse.data.message) {
                        return errorResponse.data.message;
                    }
                    if (errorResponse.errors && errorResponse.errors[0]) {
                        return errorResponse.errors[0];
                    }
                    if (typeof errorResponse === 'string') {
                        return errorResponse;
                    }
                    return null;
                };

                return errorResponse => {
                    // handle custom error code with modal support, defaulting to known OAuth / traditional sign-in formats
                    const errorMessage = getCustomErrorMessage(errorResponse);
                    if (isCustomCodeError(errorMessage)) {
                        DialogModal.alert({
                            content: translationHelper.get(errorMessage, {
                                brandEmail: targetBrandConfig(
                                    undefined,
                                    ConfigFactory.getSync(),
                                ).emailAddressForUsername('support'),
                            }), // should already be localized
                            classes: ['server-error-modal'],
                            title: translationHelper.get('server_error'),
                        });
                    } else {
                        scope.form_errors[fieldName] = errorMessage;
                    }
                };
            },
        };
    },
]);
