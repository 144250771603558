import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Authentication/angularModule/views/forgot_password_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import requestSent from 'vectors/request-sent.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('forgotPasswordForm', [
    '$injector',

    function factory($injector) {
        const DeviseClient = $injector.get('DeviseClient');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const AuthFormHelperMixin = $injector.get('AuthFormHelperMixin');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                urlPrefix: '@',
            },
            link(scope) {
                scope.requestSent = requestSent;

                const translationHelper = new TranslationHelper('authentication.forgot_password');

                NavigationHelperMixin.onLink(scope);
                AuthFormHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope, { config: ConfigFactory.getSync() });

                scope.preventSubmit = false;
                scope.form = {};
                scope.form_errors = {};
                scope.submitted = false;

                scope.requestPasswordReset = () => {
                    scope.preventSubmit = true;

                    DeviseClient.requestResetPasswordToken(scope.form)
                        .then(
                            () => {
                                scope.preventSubmit = false;
                                scope.submitted = true;
                            },
                            resp => {
                                scope.preventSubmit = false;
                                scope.submitted = false;

                                if (resp && resp.data && resp.data.errors && resp.data.errors.length > 0) {
                                    scope.form_errors.email = resp.data.errors[0];
                                } else {
                                    scope.form_errors.email = translationHelper.get('unable_to_process_your_request');
                                }
                            },
                        )
                        .finally(() => {
                            safeApply($rootScope);
                        });
                };
            },
        };
    },
]);
